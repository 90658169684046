import { DialogParams } from '../../interfaces/dialog-params';
import { BsModalRef } from 'ngx-bootstrap';
import { DialogService } from '../../services/dialog/dialog.service';
import { CurrentUser } from '../../interfaces/current-user';
import { CurrentUserService } from '../../services/current-user/current-user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  currentUser: CurrentUser;
  userDataSubscription: Subscription;
  constructor(private userService: CurrentUserService,
    private dialogService: DialogService) {}

  ngOnInit() {
    this.userDataSubscription = this.userService.getUserData().subscribe((data: CurrentUser) => {
      this.currentUser = data;
    });
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
  }

  logout() {
    const dialogRef: BsModalRef = this.dialogService.openDialog(<DialogParams>{
      type: 'primary',
      title: 'Logout',
      message: 'Are you shure you want to logout?',
      buttonCancelTitle: 'Cancel',
      buttonOkTitle: 'Logout'
    });
    dialogRef.content.onButtonCancelClick = () => {
      dialogRef.hide();
    };
    dialogRef.content.onButtonOkClick = () => {
      dialogRef.hide();
      this.userService.logout();
    }
  }
}

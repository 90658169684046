import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Organization } from '../../interfaces/organization';
import { Network } from '../../interfaces/network';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  set organization(organization: Organization) {
    this.storage.set('currentOrganization', organization);
  }

  get organization(): Organization {
    return this.storage.get('currentOrganization');
  }

  set network(network: Network) {
    this.storage.set('currentNetwork', network);
  }

  get network(): Network {
    return this.storage.get('currentNetwork');
  }
}

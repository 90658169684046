import { CurrentUser } from '../../interfaces/current-user';
import { CurrentUserService } from '../current-user/current-user.service';
import { Organization } from '../../interfaces/organization';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { apiUrl } from '../config';
import { ReplaySubject } from 'rxjs/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private organizationsSubject = new ReplaySubject<[Organization]>(1);
  constructor(private http: HttpClient,
    private userService: CurrentUserService
  ) {
    this.userService.getUserData().subscribe((user: CurrentUser) => {
      if (user.id) {
        this.getOrganizationsFromServer()
        .subscribe((organizations: [Organization]) => {
          this.organizationsSubject.next(organizations);
        });
      }
    });
  }

  getOrganizations(): Observable<[Organization]> {
    return this.organizationsSubject.asObservable();
  }

  private getOrganizationsFromServer(): Observable<any> {
    return this.http.get(`${apiUrl}/v1/users/current/organizations`);
  }
}

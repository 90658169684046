import { DialogModalComponent } from '../../components/dialog-modal/dialog-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DialogParams } from '../../interfaces/dialog-params';
import { BsModalService } from 'ngx-bootstrap';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private modalService: BsModalService) { }

  openDialog(params: DialogParams) {
    const modalClass: Array<string> = ['modal-dialog-centered'];
    switch (params.type) {
      case 'success':
        modalClass.push('modal-success');
        break;
      case 'warning':
        modalClass.push('modal-warning');
        break;
      case 'danger':
        modalClass.push('modal-danger');
        break;
      case 'primary':
        modalClass.push('modal-primary');
        break;
    }
    const modalRef: BsModalRef = this.modalService.show(DialogModalComponent, {
      initialState: params,
      class: modalClass.join(' ')
    });
    return modalRef;
  }
}

import { CurrentUserService } from './services/current-user/current-user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUser } from './interfaces/current-user';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private userService: CurrentUserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const url: string = state.url;
      const path = next.url.length ? next.url[0].path : '';
      if (['login'].indexOf(path) !== -1) {
        return this.checkActivateAuthUrl(next.url);
      } else {
        return this.checkLogin(url);
      }
  }

  checkLogin(url: string): Observable<boolean> {
    return this.userService.getUserData().pipe(
      map((result: CurrentUser) => {
          if (!result) {
            this.router.navigate(['/login']);
            return false;
          } else {
            if (result.user_roles_id === 3 && url !== '/access-codes') {
              this.router.navigate(['/access-codes']);
              return false;
            }
            return true;
          }
      })
    );
  }

  checkActivateAuthUrl(url: Array<any>): Observable<boolean> {
    return this.userService.getUserData().pipe(
      map((result: CurrentUser) => {
          if (result) {
              this.router.navigate(['/']);
              return false;
          } else {
            return true;
          }
      })
    );
  }
}

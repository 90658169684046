import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent implements OnInit {
  type = 'success';
  title = '';
  message = '';
  buttonCancelTitle;
  onButtonCancelClick: any;
  buttonOkTitle;
  onButtonOkClick: any;
  constructor(public activeModal: BsModalRef) { }

  ngOnInit() {
  }

  buttonCancelClick() {
    if (typeof(this.onButtonCancelClick) === 'function') {
      this.onButtonCancelClick();
    }
  }

  buttonOkClick() {
    if (typeof(this.onButtonOkClick) === 'function') {
      this.onButtonOkClick();
    }
  }
}

import { NetworkTraffic } from '../../interfaces/network-traffic';
import { Organization } from '../../interfaces/organization';
import { Network } from '../../interfaces/network';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {of as observableOf,  Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { apiUrl } from '../config';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { BehaviorSubject } from 'rxjs';
import { CurrentNetworkParams } from '../../interfaces/current-network-params';

@Injectable({
  providedIn: 'root'
})
export class NetworksService {
  private networksSubject = new ReplaySubject<[]>(1);
  currentParams = <CurrentNetworkParams>{
    organization: <Organization>{},
    network: <Network>{}
  };
  private currentParamsSubject = new BehaviorSubject(this.currentParams);
  constructor(private http: HttpClient) { }

  setCurrentOrganizationAndGetNetworks(organization: Organization): Observable<[]> {
    this.currentParams.organization = organization;
    this.currentParams.network = <Network>{};
    console.log('set current organization ', organization);
    this.currentParamsSubject.next(this.currentParams);
    return this.getNetworksFromServer(organization.id)
      .pipe(
        map((networks) => {
          console.log('get networks', networks);
          this.networksSubject.next(networks);
          return networks;
        }),
        catchError(err => {
          console.log('error get networks:', err);
          this.networksSubject.next([]);
          return Observable.of([]);
        })
      );
  }

  setCurrentNetwork(network: Network) {
    this.currentParams.network = network;
    this.currentParamsSubject.next(this.currentParams);
  }

  getCurrentParams(): Observable<CurrentNetworkParams> {
    return this.currentParamsSubject.asObservable();
  }

  getCurrentNetworks(): Observable<[]> {
    return this.networksSubject.asObservable();
  }

  private getNetworksFromServer(organizationId): Observable<any> {
    return this.http.get(`${apiUrl}/v1/users/current/organizations/${organizationId}/networks`);
  }

  getTrafficByNetworkid(networkId, timespan?: string, deviceType?: string): Observable<any> {
    if (!timespan) {
      timespan = '7200';
    }
    let params = new HttpParams();
    params = params.append('timespan', timespan);
    if (deviceType) {
      params = params.append('device_type', deviceType);
    }

    return this.http.get(`${apiUrl}/v1/users/current/networks/${networkId}/traffic`, {params})
      .pipe(
        catchError(err => {
          return Observable.of([]);
        })
      );
  }

  // network settingd methods
  getNetworkSetting(networkId: string): Observable<any> {
    return this.http.get(`${apiUrl}/v1/users/current/networks/${networkId}/network-setting`)
      .pipe(
        catchError(err => {
          return Observable.of({});
        })
      );
  }

  createNetworkSetting(networkId: string, networkData) {
    return this.http.post(`${apiUrl}/v1/users/current/networks/${networkId}/network-setting`, networkData)
    .pipe(
      map((result: any) => {
        return true;
      }),
      catchError(err => {
        console.log(err);
        return observableOf(false);
      })
    );
  }

  updateNetworkSetting(networkId: string, networkData) {
    return this.http.put(`${apiUrl}/v1/users/current/networks/${networkId}/network-setting`, networkData)
    .pipe(
      map((result: any) => {
        return true;
      }),
      catchError(err => {
        console.log(err);
        return observableOf(false);
      })
    );
  }
}

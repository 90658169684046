import { AuthHttpInterceptor } from './auth-http-interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { StorageServiceModule } from 'ngx-webstorage-service';

import { AppComponent } from './app.component';

// Import containers
import {
  FullLayoutComponent,
  SimpleLayoutComponent
} from './containers';

const APP_CONTAINERS = [
  FullLayoutComponent,
  SimpleLayoutComponent
]

// Import components
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  // APP_SIDEBAR_NAV
} from './components';

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  // APP_SIDEBAR_NAV
]

// // Import directives
// import {
//   AsideToggleDirective,
//   NAV_DROPDOWN_DIRECTIVES,
//   ReplaceDirective,
//   SIDEBAR_TOGGLE_DIRECTIVES
// } from './directives';

// const APP_DIRECTIVES = [
//   AsideToggleDirective,
//   NAV_DROPDOWN_DIRECTIVES,
//   ReplaceDirective,
//   SIDEBAR_TOGGLE_DIRECTIVES
// ]

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared/shared.module';
import { DialogModalComponent } from './components/dialog-modal/dialog-modal.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    SharedModule,
    StorageServiceModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    DialogModalComponent,
    // ...APP_DIRECTIVES,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true
  }
  ],
  entryComponents: [
    DialogModalComponent
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../config';
import { Observable } from 'rxjs/Observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CurrentUser } from '../../interfaces/current-user';
import { of, throwError } from 'rxjs';
import { ReplaySubject } from 'rxjs/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private userData: CurrentUser;
  private userDataSubject = new ReplaySubject<Object>(1);
  constructor(private http: HttpClient,
    private router: Router
  ) {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      this.userData = userData;
      this.getCurrentUser();
    } else {
      this.userDataSubject.next(false);
    }
  }

  getCurrentUser() {
    this.getUserInfo().subscribe(userInfo => {
      this.userData = {...this.userData, ...userInfo};
      this.userDataSubject.next(this.userData);
    }, error => {
      console.log('error getUserInfo:', error);
      this.userDataSubject.next(false);
    });
  }

  getUserData(): Observable<Object> {
    return this.userDataSubject.asObservable();
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(`${apiUrl}/auth/user`, {username, password})
      .pipe(
        mergeMap((result: any) => {
          if (result.success && result.token && result.userId) {
            this.userData = <CurrentUser> {
              id: result.userId,
              token: result.token
            };
            localStorage.setItem('userData', JSON.stringify(this.userData));
            return this.getUserInfo();
          } else {
            return throwError({success: false});
          }
        }),
        map((userInfo: any) => {
          this.userData = {...this.userData, ...userInfo};
          this.userDataSubject.next(this.userData);
          return {success: true};
        }),
        catchError(err => {
          let error = {success: false};
          if (err.status === 401 && err.error) {
            error = err.error;
          }
          return of(error);
        })
      );
  }

  logout() {
    if (this.router.url !== '/login') {
      localStorage.clear();
      this.userData = <CurrentUser> {};
      this.userDataSubject.next(false);
      console.log('goto login');
      this.router.navigate(['login']);
    }
  }

  private getUserInfo(): Observable<any> {
    return this.http.get(`${apiUrl}/v1/users/current`);
  }
}

import { RouterModule } from '@angular/router';
import { APP_SIDEBAR_NAV } from '../../components/app-sidebar-nav/app-sidebar-nav.component';
import { TeletrendHeaderButtonsComponent } from '../../components/teletrend-header-buttons/teletrend-header-buttons.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MomentModule } from 'ngx-moment';

import { LocalStorageService } from '../../services/local-storage/local-storage.service';

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from '../../directives';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    MomentModule
  ],
  declarations: [
    APP_SIDEBAR_NAV,
    ...APP_DIRECTIVES,
    TeletrendHeaderButtonsComponent
  ],
  providers: [LocalStorageService],
  exports: [
    CommonModule,
    APP_SIDEBAR_NAV,
    ...APP_DIRECTIVES,
    TeletrendHeaderButtonsComponent,
    BsDropdownModule,
    MomentModule
  ]
})
export class SharedModule { }

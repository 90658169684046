import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/catch';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const userData = JSON.parse(localStorage.getItem('userData'));
    let headers = req.headers;
    if (userData && userData.token) {
      headers = req.headers.set('x-access-token', userData.token)
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('If-Modified-Since', '0');
    }
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({headers});

    // send cloned request with header to the next handler.
    return next.handle(authReq).catch((err: HttpErrorResponse) => {
        if (err.status === 401
             && ['/login'].indexOf(this.router.url.split(';')[0]) === -1
          ) {
          this.router.navigate(['/login']);
        }
        return throwError(err);
      }
    );
  }
}

import * as _ from 'lodash';

import { CurrentNetworkParams } from '../../interfaces/current-network-params';
import { Organization } from '../../interfaces/organization';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { Network } from '../../interfaces/network';
import { NetworksService } from '../../services/networks/networks.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-teletrend-header-buttons',
  templateUrl: './teletrend-header-buttons.component.html',
  styleUrls: ['./teletrend-header-buttons.component.scss']
})
export class TeletrendHeaderButtonsComponent implements OnInit , OnDestroy {
  organizations = [];
  networks = [];
  organizationsSubscription: Subscription;
  networksSubscription: Subscription;
  currentNetworkParams: CurrentNetworkParams;
  currentNetworkParamsSubscription: Subscription;
  constructor(private organizaionsService: OrganizationsService,
    private networksService: NetworksService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.currentNetworkParamsSubscription = this.networksService.getCurrentParams()
      .subscribe((params: CurrentNetworkParams) => {
        console.log('network params subscription:', params);
        this.currentNetworkParams = params;
      });
    this.organizationsSubscription = this.organizaionsService.getOrganizations()
      .subscribe((organizations: [Organization]) => {
      console.log('get organizations for header');
      this.organizations = organizations;
      if (!this.currentNetworkParams.organization.id && this.organizations.length) {
        const organizationFromStorage = this.localStorageService.organization;
        let currentIndex = 0;
        if (organizationFromStorage) {
          const organizationId = _.findIndex(this.organizations, {id: organizationFromStorage.id});
          if (organizationId !== -1) {
            currentIndex = organizationId;
          }
        } 
        this.setOrganization(organizations[currentIndex]);
      }
    });
    this.networksSubscription =  this.networksService.getCurrentNetworks()
      .subscribe((networks: []) => {
        console.log('subscribe to networks in header');
        this.networks = networks;
        if (!this.currentNetworkParams.network.id && this.networks.length) {
          const networkFromStorage = this.localStorageService.network;
          let currentIndex = 0;
          if (networkFromStorage) {
            const networkId = _.findIndex(this.networks, {id: networkFromStorage.id});
            if (networkId !== -1) {
              currentIndex = networkId;
            }
          }           
          this.setNetwork(networks[currentIndex]);
        }
    });
  }

  setOrganization(organization) {
    this.localStorageService.organization = organization;
    this.networksService.setCurrentOrganizationAndGetNetworks(organization).subscribe();
  }

  setNetwork(network) {
    this.localStorageService.network = network;
    this.networksService.setCurrentNetwork(network);
  }

  ngOnDestroy() {
    this.organizationsSubscription.unsubscribe();
    this.networksSubscription.unsubscribe();
    this.currentNetworkParamsSubscription.unsubscribe();
  }
}

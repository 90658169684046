export const navigation = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    user_role_ids: [1, 2, 4, 5],
    children: [
      {
        name: 'Traffic analytics',
        url: '/dashboard/traffic',
        icon: 'icon-shuffle',
        user_role_ids: [1, 2, 4, 5],
      },
      {
        name: 'Clients',
        url: '/dashboard/clients',
        icon: 'fa fa-users',
        user_role_ids: [1, 2, 4, 5],
      },
      {
        name: 'Clients history',
        url: '/dashboard/clients-history',
        icon: 'fa fa-history',
        user_role_ids: [1, 5],
      },
      {
        name: 'SMS Statistics',
        url: '/dashboard/sms-statistics',
        icon: 'fa fa-commenting-o',
        user_role_ids: [1, 2, 4, 5],
      },        
      {
        name: 'Access points',
        url: '/dashboard/access-points',
        icon: 'icon-feed',
        user_role_ids: [1, 2, 4, 5],
      }
    ]
  },
  {
    divider: true,
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'fa fa-cogs',
    user_role_ids: [1, 2, 4, 5],
    children: [
      {
        name: 'Splash page',
        url: '/settings/splash-page',
        icon: 'icon-screen-tablet',
        user_role_ids: [1, 2, 4, 5],
      },
      {
        name: 'Access point',
        url: '/settings/access-point',
        icon: 'icon-feed',
        user_role_ids: [1, 2, 5],
      },
      {
        name: 'Sms',
        url: '/settings/sms',
        icon: 'fa fa-comments-o',
        user_role_ids: [1, 2, 5],
      },
      {
        name: 'Network',
        url: '/settings/network',
        icon: 'icon-settings',
        user_role_ids: [1, 2, 5],
      },
      {
        name: 'Users',
        url: '/settings/users',
        icon: 'icon-people',
        user_role_ids: [1, 5],
      },
      {
        name: 'General id list',
        url: '/settings/general-id-list',
        icon: 'fa fa-list',
        user_role_ids: [1, 5]
      }
    ]
  },
  {
    divider: true
  },
  {
    name: 'Access codes',
    url: '/access-codes',
    icon: 'fa fa-list',
    user_role_ids: [1, 2, 3, 4],
  }
];

import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import {
  FullLayoutComponent,
  SimpleLayoutComponent
} from './containers';

export const routes: Routes = [
  {
    path: '',
    // redirectTo: 'organization',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: SimpleLayoutComponent,
    loadChildren: './views/login/login.module#LoginModule',
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'settings',
        children: [
          {
            path: 'users',
            loadChildren: './views/users/users.module#UsersModule'
          },
          {
            path: 'splash-page',
            loadChildren: './views/splash-page/splash-page.module#SplashPageModule'
          },
          {
            path: 'access-point',
            loadChildren: './views/access-point/access-point.module#AccessPointModule'
          },
          {
            path: 'sms',
            loadChildren: './views/sms-setting/sms-setting.module#SmsSettingModule'
          },
          {
            path: 'network',
            loadChildren: './views/network-setting/network-setting.module#NetworkSettingModule'
          },
          {
            path: 'general-id-list',
            loadChildren: './views/general-id-list/general-id-list.module#GeneralIdListModule'
          }
        ]
      },
      {
        path: 'access-codes',
        loadChildren: './views/access-codes/access-codes.module#AccessCodesModule'
      },
    ],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes,
    {
      onSameUrlNavigation: 'reload',
      // enableTracing: true  // <-- debugging purposes only
    }
  )],
  exports: [ RouterModule ],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
